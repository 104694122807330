import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/ricoklatte/WebstormProjects/portfolio/components/Approach.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PinContainer"] */ "/Users/ricoklatte/WebstormProjects/portfolio/components/ui/3d-pin.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BentoGrid","BentoGridItem"] */ "/Users/ricoklatte/WebstormProjects/portfolio/components/ui/bento-grid.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FloatingNav"] */ "/Users/ricoklatte/WebstormProjects/portfolio/components/ui/floating-navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InfiniteMovingCards"] */ "/Users/ricoklatte/WebstormProjects/portfolio/components/ui/infinite-moving-cards.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/Users/ricoklatte/WebstormProjects/portfolio/components/ui/moving-border.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TextGenerateEffect"] */ "/Users/ricoklatte/WebstormProjects/portfolio/components/ui/text-generate-effect.tsx");
