"use client";

import { useEffect } from "react";
import { motion, stagger, useAnimate } from "framer-motion";
import { cn } from "@/lib/utils";
export const TextGenerateEffect = ({
  words,
  className,
  filter = true,
  duration = 0.5
}: {
  words: string;
  className?: string;
  filter?: boolean;
  duration?: number;
}) => {
  const [scope, animate] = useAnimate();
  const wordsArray = words.split(" ");
  useEffect(() => {
    animate("span", {
      opacity: 1,
      filter: filter ? "blur(0px)" : "none"
    }, {
      duration: duration ? duration : 1,
      delay: stagger(0.2)
    });
  }, [scope.current]);
  const renderWords = () => {
    return <motion.div ref={scope} data-sentry-element="unknown" data-sentry-component="renderWords" data-sentry-source-file="text-generate-effect.tsx">
                {wordsArray.map((word, idx) => {
        return <motion.span key={word + idx} className={`${idx > 3 ? "text-purple" : "dark:text-white text-black"} opacity-0`} style={{
          filter: filter ? "blur(10px)" : "none"
        }}>
                            {word}{" "}
                        </motion.span>;
      })}
            </motion.div>;
  };
  return <div className={cn("font-bold", className)} data-sentry-component="TextGenerateEffect" data-sentry-source-file="text-generate-effect.tsx">
            <div className="my-4">
                <div className="dark:text-white text-black leading-snug tracking-wide">
                    {renderWords()}
                </div>
            </div>
        </div>;
};